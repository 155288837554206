﻿//https://stackoverflow.com/a/26850312/171703
$(document).on('focus', ".datepicker", function () {
    var startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);

    $(this).datepicker({
        format: 'dd/mm/yyyy', // TODO
        zIndexOffset: '1001',
        startDate: startDate,
    });
});
